import React, { useEffect } from 'react';

import { css } from '@oddcommon/utils';
import Link from '@components/Link';
import CTA from '@components/CTA';
import { useInView } from 'react-intersection-observer';

import styles from './CaseStudyCard.module.scss';
import { formatClients } from '@js-utils/formatting';
import CaseStudyThumb from '@components/CaseStudyThumb';

const CASE_STUDY_PATH = 'work';
const WIP_PATH = 'wip';

const CaseStudyCard = props => {
  const {
    client,
    primaryImage,
    thumbnailImage,
    projectTitle,
    isPublic,
    slug,
    orientation,
    loaded,
  } = props;
  const [ref, inView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [copyRef, copyInView] = useInView({ threshold: 0.1, triggerOnce: true });

  const url = `/${isPublic ? CASE_STUDY_PATH : WIP_PATH}/${slug}`;

  let clientName = formatClients(client);

  useEffect(() => {
    if (primaryImage.file.contentType.indexOf('image') > -1) {
      loaded();
    }
  }, []);

  return (
    <div
      className={css(
        styles.card,
        !isPublic && styles.isPrivate,
        styles[orientation],
        inView && styles.ready,
        copyInView && styles.copyReady
      )}
    >
      <div ref={ref} className={styles.thumb}>
        <Link to={url} className={styles.thumbContent} title={`${clientName} - ${projectTitle}`}>
          <CaseStudyThumb
            styles={styles}
            content={primaryImage}
            thumbnailImage={thumbnailImage}
            alt={`${clientName} - ${projectTitle}`}
            loaded={loaded}
          />
          {!isPublic ? (
            <div className={styles.comingSoon}>
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          ) : (
            ''
          )}
        </Link>
      </div>
      <div ref={copyRef} className={styles.copy}>
        {clientName && (
          <div>
            <div className={styles.clientWrapper}>
              <p className={styles.client}>{clientName}</p>
            </div>
          </div>
        )}
        <div>
          <Link to={url} className={styles.titleWrapper}>
            <h3 className={styles.title}>{projectTitle}</h3>
          </Link>
        </div>
        <div>
          <div className={styles.linkWrapper}>
            <CTA to={url} className={styles.link}>
              {isPublic ? 'View Case Study' : 'Coming Soon'}
            </CTA>
          </div>
        </div>
      </div>
    </div>
  );
};

CaseStudyCard.defaultProps = {
  orientation: 'left',
};

export default CaseStudyCard;
