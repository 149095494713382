import React, { useRef, useContext, useEffect, useState, useCallback } from 'react';
import { GlobalContext } from '@context';

import styles from './index.module.scss';
import ScrollMarquee from '@components/ScrollMarquee';
import { graphql } from 'gatsby';
import CaseStudyCard from '@components/CaseStudyCard';
import Meta from '@components/Meta';
import { css } from '@oddcommon/utils';

const logoVideos = ['liquid.mp4', '3d.mp4', 'graphic.mp4'];

const videos = ['internet-alt.mp4', 'not-internet.mp4', 'bk.mp4', ...logoVideos];
const LOGO_VIDEOS_INDEX = 3;

const HomePage = ({ data }) => {
  const { hover, firstTimer, actions } = useContext(GlobalContext);
  const [activeVideo, setActiveVideo] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [loadedProjects, setLoadedProjects] = useState(0);
  const videoRef = useRef([]);

  const caseStudiesList = data.contentfulHomePage.caseStudies;

  // Mouse events
  const handleMouseIn = video => {
    actions.setHover(video);
  };

  const handleMouseOut = useCallback(() => {
    actions.setHover(null);
    setActiveVideo(null);
  }, [actions]);

  // Check if the current video is in the target & set active styles
  const checkHover = target => {
    if (activeVideo && target.includes(activeVideo)) return styles.active;
    return '';
  };

  const caseStudies = caseStudiesList.map((caseStudy, index) => {
    return (
      <CaseStudyCard
        key={caseStudy.projectTitle}
        orientation={index % 2 ? 'right' : 'left'}
        {...caseStudy}
        loaded={() => {
          const _loaded = loadedProjects;
          setLoadedProjects(_loaded + 1);
        }}
      />
    );
  });

  // Hover set
  useEffect(() => {
    if (hover) {
      // Assign video
      let video = hover;

      // Check if video is logo, if so select random video
      if (video === 'logo') {
        const logoVideo = Math.round(Math.random() * (logoVideos.length - 1));
        video = logoVideos[logoVideo];
      }

      // Force play on mobile
      videoRef.current[video].play();

      // Set video
      setActiveVideo(video);
    }
  }, [hover]);

  useEffect(() => {
    if (loadedProjects == 2) {
      actions.setPageLoaded(true);
    }
  }, [loadedProjects]);

  useEffect(() => {
    window.addEventListener('scroll', handleMouseOut);
    return () => {
      window.removeEventListener('scroll', handleMouseOut);
    };
  }, [handleMouseOut]);

  useEffect(() => {
    if (mounted) {
      setTimeout(() => {
        actions.setFirstTimer(true);
      }, 2000);
    }
  }, [mounted]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <Meta />
      <div className={`${styles.videos} ${mounted ? styles.ready : ''}`}>
        {videos.map((vid, index) => (
          <video
            key={index}
            ref={el => {
              videoRef.current[vid] = el;
            }}
            className={css(
              activeVideo && activeVideo === vid && styles.active,
              index >= LOGO_VIDEOS_INDEX && styles.logoVideo
            )}
            src={`/videos/${vid}`}
            muted
            playsInline
            loop
            preload="metadata"
          />
        ))}
      </div>
      <div className={`${styles.base} ${hover ? styles.hover : ''}`}>
        <section
          className={css(
            styles.heroSection,
            mounted && styles.ready,
            firstTimer && styles.introduced
          )}
        >
          <h1>
            <em
              className={checkHover(logoVideos)}
              onMouseEnter={() => handleMouseIn('logo')}
              onMouseLeave={handleMouseOut}
            >
              OddCommon
            </em>{' '}
            <span>is an independent design and technology agency based in</span>{' '}
            <em
              className={checkHover(['bk.mp4'])}
              onMouseEnter={() => handleMouseIn('bk.mp4')}
              onMouseLeave={handleMouseOut}
            >
              Brooklyn, New York.
            </em>
          </h1>

          <p className={styles['also-title']}>
            <span>Basically, we make</span>{' '}
            <em
              className={checkHover(['internet-alt.mp4'])}
              onMouseEnter={() => handleMouseIn('internet-alt.mp4')}
              onMouseLeave={handleMouseOut}
            >
              cool things for the internet.
            </em>{' '}
            <br className={styles.noOrphansPlease} />
            <span>But then also cool things that</span>{' '}
            <em
              className={checkHover(['not-internet.mp4'])}
              onMouseEnter={() => handleMouseIn('not-internet.mp4')}
              onMouseLeave={handleMouseOut}
            >
              aren’t for the internet.
            </em>
          </p>
        </section>
        <section className={css(styles.caseStudies, hover && styles.hover)}>
          <h2 className="visuallyHidden">Recent Work</h2>
          {caseStudies}
        </section>
        <section className={styles.marqueeSection}>
          <ScrollMarquee
            items={[
              'Digital Branding',
              'Design',
              'Interactive',
              'Experiential',
              'Product Development',
            ]}
            direction="left"
            speed={300}
            hover={hover}
          />
          <ScrollMarquee
            items={[
              'Product Development',
              'Experiential',
              'Interactive',
              'Design',
              'Digital Branding',
            ]}
            direction="right"
            speed={300}
            hover={hover}
          />
        </section>
      </div>
    </>
  );
};
export const HomePageQuery = graphql`
  query HomePageQuery {
    contentfulHomePage {
      caseStudies {
        primaryImage {
          gatsbyImageData(formats: [AUTO, WEBP])
          file {
            contentType
            url
          }
        }
        thumbnailImage {
          gatsbyImageData(formats: [AUTO, WEBP])
        }
        slug
        projectTitle
        isPublic: public
        client {
          name
        }
      }
    }
  }
`;
export default HomePage;
